import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { IAuthData, IAuthState } from "./authSlice.interface";

const initialState: IAuthState = {
    loading: false,
}

export const loginUser = createAsyncThunk(
    "auth/login",
    async (props: IAuthData, { rejectWithValue }) => {
        try {
            const { email, password, rememberMe } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URI}/api/identity/accounts/authenticate`, { Email: email, Password: password, RememberMe: rememberMe }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.signInResultMessage);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const registerUser = createAsyncThunk(
    "auth/register",
    async (props: IAuthData, { rejectWithValue }) => {
        try {
            const { acceptTerms, email, password } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URI}/api/identity/accounts/register`, { AcceptTerm: acceptTerms, Email: email, Password: password }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
)

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearErrors(state) {
            state.error = undefined;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(loginUser.pending, (state, action) => {
                state.loading = true;
                state.isAuthenticated = false;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = true;
                state.isAuthenticated = action.payload.succeeded;
                state.user = action.payload.user;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = true;
                state.isAuthenticated = false;
                state.user = undefined;
                state.error = action.payload;
            })
    },
})

export const { clearErrors } = authSlice.actions;
export default authSlice.reducer;