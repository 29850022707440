import styled from "styled-components";

export const AuthSpan = styled.span`
    display: grid;
    position: absolute;
    top: 40%;
    bottom: 0;
    right: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    place-content: center;
    color: #9CA3AF;
    width: 1rem;
    height: 1rem;
`;

export const AuthSvg = styled.svg`
    color: #9CA3AF;
    width: 1rem;
    height: 1rem;
`;