import React from 'react'

type Props = {}

const ErrorPage = (props: Props) => {
  return (
    <div>
        <h1>404 Error</h1>
        <p>Page Not Found</p>
    </div>
  )
}

export default ErrorPage