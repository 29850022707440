import styled from "styled-components";

export const AuthInput = styled.input`
    outline: none;
    border: 1px solid #e5e7eb;
    margin: 8px 0;
    background-color: #fff;
    padding: 1rem;
    padding-right: 3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 300px;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
`;