import React from 'react';
import { useNavigate } from 'react-router'
import { HeaderCenterSection, HeaderLeftSection, HeaderLinks, HeaderNav, HeaderRightSection, StyledHeader, StyledLink } from '../styles/Header.styled';

type Props = {}

const Header = (props: Props) => {
    return (
        <StyledHeader>
            <HeaderNav>
                <HeaderCenterSection>
                    <StyledLink to={'/'}>ADAS TEMPLATE SOLUTION</StyledLink>
                </HeaderCenterSection>

                <HeaderRightSection>
                    <HeaderLinks>
                        <StyledLink to={'/login'}>Login</StyledLink>
                        <StyledLink to={'/Register'}>Register</StyledLink>
                    </HeaderLinks>
                </HeaderRightSection>
            </HeaderNav>
        </StyledHeader>
    )
}

export default Header