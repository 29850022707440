import React from 'react'

type Props = {}
console.log('process.env');
console.log(process.env);
const Home = (props: Props) => {
  return (
    <div>
      <h1>ADAS TEMPLATE PROJECT</h1>
      <p>REACT_APP_API_BASE_URI:</p>
      <p>{process.env.REACT_APP_API_BASE_URI}</p>
    </div>
  )
}

export default Home