import React, { useEffect, useState } from 'react';
import { AuthForm, AuthFormLink, AuthFormTitle, StyledAuthFormLink } from '../styles/Form.styled';
import { AuthInput } from '../styles/Input.styled';
import { AuthButton } from '../styles/Button.styled';
import { AuthSpan, AuthSvg } from '../styles/Span.styled';
import { AuthInputContainer } from '../styles/Container.styled';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearErrors, loginUser } from '../../features/auth/authSlice';

type Props = {}

const Login = (props: Props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);

    const { isAuthenticated, user, loading, error } = useAppSelector((state) => state.auth);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/");
            toast.dismiss();
            toast.success("Login successful");
        }

        if (typeof error === 'string') {
            toast.dismiss();
            toast.error(error);
            dispatch(clearErrors());
        }
    }, [dispatch, error, isAuthenticated, navigate])

    const togglePassword = () => {
        var x = document.getElementById("password") as HTMLInputElement | null;
        if (x != null) {
            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        }
    }

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        if (email === "") {
            toast.error("Enter your email")
            return
        }

        if (password === "") {
            toast.error("Enter your password")
            return
        }

        toast.info("Logging in...", {
            autoClose: false,
        });
        dispatch(loginUser({ email, password, rememberMe }))
    }

    return (
        <>
            <div>
                <AuthForm>
                    <AuthFormTitle>Sign in to your account</AuthFormTitle>
                    <AuthInputContainer>
                        <AuthInput placeholder="Enter email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <AuthSpan>
                            <AuthSvg stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path>
                            </AuthSvg>
                        </AuthSpan>
                    </AuthInputContainer>
                    <AuthInputContainer>
                        <AuthInput placeholder="Enter password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} id='password' />
                        <AuthSpan onClick={togglePassword} style={{ cursor: "pointer" }}>
                            <AuthSvg stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path>
                                <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path>
                            </AuthSvg>
                        </AuthSpan>
                    </AuthInputContainer>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className='m-0'><small>Remember my account details?</small></p>
                        <input type="checkbox" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                    </div>
                    <AuthButton type="submit" onClick={submitHandler}>
                        Sign in
                    </AuthButton>

                    <AuthFormLink className='mb-0'>
                        Forgot password?
                        <StyledAuthFormLink to={'/forgotPassword'}>Reset it</StyledAuthFormLink>
                    </AuthFormLink>

                    <AuthFormLink>
                        No account?
                        <StyledAuthFormLink to={'/register'}>Sign up</StyledAuthFormLink>
                    </AuthFormLink>

                </AuthForm>

            </div>
        </>
    )
}

export default Login