import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    // imports here
    * {
        box-sizing: border-box;
    }

    body {
        margin: 0;
    }

    img {
        max-width: 100%;
    }
`

export default GlobalStyles