import styled from "styled-components";
import { Link } from 'react-router-dom';

export const AuthForm = styled.form`
    background-color: #fff;
    display: block;
    padding: 1rem;
    max-width: 350px;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
`

export const AuthFormTitle = styled.p`
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    text-align: center;
    color: #000;
`;

export const AuthFormLink = styled.p`
    color: #6B7280;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
`;

export const StyledAuthFormLink = styled(Link)`
    text-decoration: underline;
    &:hover {
        transform: scale(0.98);
        color: black;
    }
`;