import React from 'react';
import logo from './logo.svg';
import './App.css';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './components/styles/Global';
import { AppContainer } from './components/styles/Container.styled';
import { Route, Routes } from 'react-router';
import Home from './components/Home';
import ErrorPage from './components/ErrorPage';
import Header from './components/layout/Header';
import Login from './components/user/Login';
import 'react-toastify/dist/ReactToastify.css';
import Register from './components/user/Register';

const theme = {
  colors: {

  }
}

function App() {
  const handleClick = async () => {
    const response = await fetch('https://localhost:7184/api/Accounts/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    });
    // handle response here
  };
  return (
    <>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <>
            <Header />
            <AppContainer>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </AppContainer>
            {/* Footer */}
          </>
        </>
      </ThemeProvider>
    </>
  );
}

export default App;
