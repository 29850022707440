import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledHeader = styled.header`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const StyledLink = styled(Link)`
    color: black !important;
    text-decoration: none;
    margin-left: 1em;
    margin-right: 1em;
    &:hover {
        transform: scale(0.98);
        color: black;
    }
`;

export const HeaderLinks = styled.ul`
    list-style-type: none;
    margin-top: 15px;
    width: auto;
`;

export const HeaderNav = styled.nav`
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;

    @media (max-width: 992px) {
        padding-top: 20px;
        padding-bottom: 40px;
    }
`;

export const HeaderCenterSection = styled.div`
    text-align: start;
    display: flex;
    align-items: center;

    @media (max-width: 992px) {
        text-align: center;
    }
`;

export const HeaderLeftSection = styled.nav`
    display: none;

    ${StyledLink} {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    @media (max-width: 992px) {
        display: block;
    }
`;

export const HeaderRightSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;